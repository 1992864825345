import React, { useState } from "react";
import {
  Box,
  Typography,
  Hidden,
  Drawer,
  IconButton,
  Button,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import TuneIcon from "@mui/icons-material/Tune";
import SearchBar from "../search";
import { Logout, PersonPinCircleOutlined } from "@mui/icons-material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { GridMenuIcon } from "@mui/x-data-grid";
import { getUserInfo } from "../../helpers/user";

const adminNavLinks = [
  {
    title: "Home",
    path: "/",
    icon: HomeIcon,
  },
  {
    title: "Controls",
    path: "/controls",
    icon: TuneIcon,
  },
  {
    title: "Control Sets",
    path: "/control-sets",
    icon: CollectionsBookmarkIcon,
  },
  {
    title: "Engagements",
    path: "/engagements",
    icon: PeopleOutlineIcon,
  },
  {
    title: "Evidence Requests",
    path: "/evidencerequests",
    icon: BackupTableIcon,
  },
  {
    title: "Users",
    path: "/users",
    icon: PersonPinCircleOutlined,
  },
  {
    title: "Compliance Standards",
    path: "/compliance-standards",
    icon: HistoryEduIcon,
  },
  {
    title: "Scopes",
    path: "/scopes",
    icon: AddCommentIcon,
  },
];

const auditorNavLinks = [
  {
    title: "Home",
    path: "/",
    icon: HomeIcon,
  },
  {
    title: "Controls",
    path: "/controls",
    icon: TuneIcon,
  },
  {
    title: "Control Sets",
    path: "/control-sets",
    icon: CollectionsBookmarkIcon,
  },
  {
    title: "Engagements",
    path: "/engagements",
    icon: PeopleOutlineIcon,
  },
  {
    title: "Evidence Requests",
    path: "/evidencerequests",
    icon: BackupTableIcon,
  },
];

const userNavLinks = [
  {
    title: "Home",
    path: "/",
    icon: HomeIcon,
  },
  {
    title: "Engagements",
    path: "/engagements",
    icon: PeopleOutlineIcon,
  },
  {
    title: "Evidence Requests",
    path: "/evidencerequests",
    icon: BackupTableIcon,
  },
];

const orgAdminNavLinks = [
  {
    title: "Home",
    path: "/",
    icon: HomeIcon,
  },
  {
    title: "Engagements",
    path: "/engagements",
    icon: PeopleOutlineIcon,
  },
  {
    title: "Evidence Requests",
    path: "/evidencerequests",
    icon: BackupTableIcon,
  },
  {
    title: "Users",
    path: "/users",
    icon: PersonPinCircleOutlined,
  },
];

const Sidebar = () => {
  const { pathname } = useLocation();
  const userInfo = getUserInfo();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navLinks =
    userInfo.role === "admin"
      ? adminNavLinks
      : userInfo.role === "auditor"
      ? auditorNavLinks
      : userInfo.role === "user"
      ? userNavLinks
      : userInfo.role === "org_admin"
      ? orgAdminNavLinks
      : [];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#161d2f",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={toggleMobileMenu}
          sx={{
            display: { sm: "block", md: "block", lg: "none" },
            color: "white",
            justifyContent: "flex-start",
            ":hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <GridMenuIcon sx={{ fontSize: 30 }} />
        </Button>
        <Box
          sx={{
            display: {
              xs: isMobileMenuOpen ? "block" : "none",
              sm: isMobileMenuOpen ? "block" : "none",
              md: isMobileMenuOpen ? "block" : "none",
              lg: "block",
            },
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            component="h1"
            my={2}
            mx={2}
            fontSize={12}
            fontWeight={600}
            color="white"
          >
            Securisea Evidence Portal
          </Typography>
          <hr />
          {/* User Info */}
          <Typography sx={{ color: "white", padding: 2, pb: 0 }}>
            {userInfo.name}
            <br />
          </Typography>

          <Typography
            sx={{ color: "white", pt: 0, padding: 2, pb: 3, fontWeight: 800 }}
          >
            {userInfo.organization}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#1f2940",
            }}
          >
            {navLinks.map((item) => (
              <Box
                key={item.title}
                sx={{
                  width: "100%",
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 0,
                  backgroundColor:
                    pathname === item.path ? "secondary.main" : "transparent",
                  py: 1,
                  ":hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                <Link to={item.path} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "grey.300",
                      fontWeight: pathname === item.path ? "900" : "600",
                      padding: 1,
                      pl: 2,
                      pr: 3,
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>

          <Link to={"/logout"} style={{ textDecoration: "none" }}>
            <Box
              sx={{
                display: "inline-flex",
                px: 2,
                pt: 4,
                pb: 2,
                color: "white",
                ":hover": {
                  color: "#bbbbbb",
                },
              }}
            >
              <Logout /> <Typography>Logout </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
