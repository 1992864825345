import { getCookie, setCookie } from "../../api/apiClient";
import { fetchCurrentUser } from "../../pages/finish-login";

export const getUserInfo = () => {
  return JSON.parse(getCookie("user_data") || "{}");
};

export const isUserAllowed = () => {
  const userInfo = getUserInfo();
  return userInfo.access_enabled === true;
};

export const isUserCompliantWithTOSRequirements = () => {
  fetchCurrentUser();
  const userInfo = getUserInfo();

  if (userInfo.must_accept_tos === false) {
    return true;
  }

  if (
    userInfo.must_accept_tos === true &&
    userInfo.has_accepted_tos === false
  ) {
    return false;
  }

  return true;
};
