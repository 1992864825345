import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useSearchUsers } from "../../hooks/users";
import { User } from "../../types/users";
import { EvidenceRequest } from "../../types/evidencerequests";

interface AddRemoveUsersModalProps {
  open: boolean;
  onClose: () => void;
  evidenceRequests: EvidenceRequest[];
  organization: string;
  onSave: (updatedEvidenceRequests: EvidenceRequest[]) => Promise<void>;
}

const AddRemoveUsersModal: React.FC<AddRemoveUsersModalProps> = ({
  open,
  onClose,
  evidenceRequests,
  organization,
  onSave,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [action, setAction] = useState<"add" | "remove">("add");

  const { data: userQueryResult } = useSearchUsers({
    q: searchQuery,
    organization,
    intent: "users",
  });

  const users = userQueryResult?.[0]?.users || [];

  const handleUserToggle = (user: User) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.some((u) => u.id === user.id)
        ? prevSelected.filter((u) => u.id !== user.id)
        : [...prevSelected, user]
    );
  };

  const handleSave = async () => {
    const updatedEvidenceRequests = evidenceRequests.map((er) => ({
      ...er,
      users:
        action === "add"
          ? [...er.users, ...selectedUsers]
          : er.users.filter((u) => !selectedUsers.some((su) => su.id === u.id)),
    }));

    await onSave(updatedEvidenceRequests);
    setSelectedUsers([]);
    setSearchQuery("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {action === "add" ? "Add Users" : "Remove Users"}
        </Typography>
        <RadioGroup
          row
          value={action}
          onChange={(e) => setAction(e.target.value as "add" | "remove")}
        >
          <FormControlLabel value="add" control={<Radio />} label="Add" />
          <FormControlLabel value="remove" control={<Radio />} label="Remove" />
        </RadioGroup>
        <TextField
          fullWidth
          label="Search Users"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />
        <List sx={{ maxHeight: 200, overflow: "auto" }}>
          {users.map((user) => (
            <ListItem
              key={user.id}
              dense
              onClick={() => handleUserToggle(user)}
            >
              <Checkbox
                edge="start"
                checked={selectedUsers.some((u) => u.id === user.id)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
          ))}
        </List>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Save Changes
        </Button>
      </Box>
    </Modal>
  );
};

export default AddRemoveUsersModal;
