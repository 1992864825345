import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { User } from "../../../types/users";
import { useGetObjects } from "../../../hooks/common";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { getUserInfo } from "../../../helpers/user";
import { Button } from "@mui/material";
import { useUpdateEvidenceRequest } from "../../../hooks/evidencerequests";

interface HasUsers {
  id?: string;
  users?: User[];
}

interface RelatedUserTableProps {
  parentObject: Partial<EvidenceRequest>;
  loadPartial: boolean;
  parentObjectUrlKey: string;
  relatedChildFieldName: string;
}

const RelatedUserTable = ({
  parentObject,
  loadPartial,
  parentObjectUrlKey,
  relatedChildFieldName,
}: RelatedUserTableProps) => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [adminMode, setAdminMode] = useState<boolean>(false);
  useEffect(() => {
    const userInfo = getUserInfo();
    if (
      userInfo.role === "admin" ||
      userInfo.role === "auditor" ||
      userInfo.role === "org_admin"
    ) {
      setAdminMode(true);
    }
  }, []);

  const [editedParentObject, setEditedParentObject] =
    useState<HasUsers>(parentObject);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  useEffect(() => {
    if (adminMode) {
      const currentSelectedIds = Object.keys(rowSelection).filter(
        (id) => rowSelection[id]
      );
      setSelectedIDs(currentSelectedIds);
    }
  }, [rowSelection, adminMode]);

  const updateEvidenceRequest = useUpdateEvidenceRequest();

  const handleSubmit = async () => {
    try {
      if ("id" in editedParentObject) {
        await updateEvidenceRequest.mutateAsync(
          editedParentObject as EvidenceRequest
        );
      }
    } catch (error) {}
    window.location.reload();
  };

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  useEffect(() => {
    const rowSelection: Record<string, boolean> = {};

    parentObject.users?.forEach((user: User) => {
      const id = user.id;
      rowSelection[id] = true;
    });

    setRowSelection(rowSelection);
    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const initialRowSelectionAndPinning = useMemo(() => {
    const rowSelection: Record<string, boolean> = {};
    const topPinning: string[] = [];

    parentObject.users?.forEach((user: User) => {
      const id = user.id;
      rowSelection[id] = true;
      topPinning.push(id);
    });

    return { rowSelection };
  }, [parentObject]);

  const relatedParentField = parentObjectUrlKey;
  const relatedChildField = relatedChildFieldName;
  const relatedParentId = parentObject.id;

  const tableName = "related_users_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly: false,
  };

  const {
    data: userQueryResults = [],
    isError: isLoadingUsersError,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useGetObjects<
    User,
    [
      "users",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
      }
    ]
  >(
    [
      "users",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly: false,
      },
    ],
    "users",
    getParams
  );

  const fetchedUsers = userQueryResults[0]?.data || [];

  useEffect(() => {
    if (editedParentObject && fetchedUsers.length > 0) {
      setEditedParentObject((prevState) => ({
        ...prevState!,
        users: fetchedUsers.filter((user) => selectedIDs.includes(user.id)),
      }));
    }
  }, [selectedIDs, fetchedUsers, initialLoad]);

  useEffect(() => {
    const count = userQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [userQueryResults]);

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Full Name",
        enableColumnFilter: false,
        enableEditing: false,
        enableSorting: false,
      },

      {
        accessorKey: "email",
        header: "Email",
        enableEditing: false,
        enableSorting: false,
      },

      {
        accessorKey: "first_name",
        header: "First Name",
      },

      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "role",
        header: "Role",
        accessorFn: (row) => row.role.split("_").join(" "),
        enableEditing: false,
      },
      {
        accessorKey: "access_enabled",
        header: "Access Enabled",
        accessorFn: (row) => (row.access_enabled ? "Yes" : "No"),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedUsers,
    positionToolbarAlertBanner: "none",
    enableRowSelection: true,

    initialState: {
      rowSelection: initialRowSelectionAndPinning.rowSelection,
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: adminMode ? setRowSelection : undefined,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableSelectAll: false,
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "100px",
      },
    },

    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingUsers,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  if (initialLoad) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <>
        <div style={{ opacity: 1, transition: "opacity 3s" }}>
          <div className="fullPageTable">
            <MaterialReactTable table={table} />

            {adminMode && (
              <>
                <Button
                  sx={{ margin: 2, marginLeft: 0 }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default RelatedUserTable;
