import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getUserInfo } from "../../helpers/user";
import { useUpdateUser, useGetUser, useAcceptTOS } from "../../hooks/users";
import { User } from "../../types/users";
import { defaultUser } from "../../components/users/edit/admin";
import { fetchCurrentUser } from "../finish-login";

interface TermsAndConditionsPopupProps {
  open?: boolean;
}

const TermsAndConditionsPopup: React.FC<TermsAndConditionsPopupProps> = ({
  open = false,
}) => {
  const [editedUser, setEditedUser] = useState<User | Partial<User>>(
    defaultUser
  );

  useEffect(() => {
    const loadUserData = async () => {
      const userData = await fetchCurrentUser();
      if (userData) {
        setEditedUser(userData);
      }
    };
    loadUserData();
  }, []);

  const handleAccept = () => {
    if (editedUser.id) {
      const acceptTOS = useAcceptTOS(editedUser.id);
      acceptTOS();
    }
  };

  return (
    <Dialog
      open={true}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">GENERAL TERMS</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ pr: { xs: 2, lg: 4 } }}>
          <Typography sx={{ pb: 2 }}>
            These terms and conditions ("Terms of Use" or the "Agreement") are
            entered into by and between you, the customer ("Customer" or "you"),
            and Securisea, Inc., a Georgia corporation that provides data
            security and compliance programs and services ("Securisea," "we,"
            "us," or "Company") (together with Customer, the "parties"), and
            govern the access to and use of the Securisea Evidence Portal (the
            "Portal", Evidence Portal" or "Services") by existing and
            prospective customers of Securisea ("Customers").
          </Typography>

          <Typography sx={{ pb: 2 }}>
            By using the Portal or by accepting these terms electronically, you
            agree to these Terms of Use and all provisions thereof, and the
            agreement formed hereby commences upon the first of these to occur.
            You represent to Securisea that you are lawfully able and authorized
            to enter into contracts on behalf of the entity you represent, and
            you warrant that you have authority to bind such entity and
            acknowledge and agree that the term "you" as used herein refers to
            the entity you represent. Either Customer or Securisea may terminate
            the Agreement at any time upon written notice, with or without
            cause, and Customer agrees that Securisea may contact Customer in
            furtherance of the automatic renewal of any Services.
          </Typography>

          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 1 - Scope
          </Typography>
          <Typography sx={{ pb: 2 }}>
            The Evidence Portal is designed to help gather and organize
            applicable data in connection with a current or planned security
            audit to be undertaken by Securisea on Customer's behalf.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 2 - Enrollment in the Securisea Evidence Portal
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) Enrollment. Enrollment is limited to current and prospective
            customers of Securisea. Customer will be enrolled in the Evidence
            Portal once it accepts these Terms of Use. Company may accept or
            decline to accept Customer in the Evidence Portal at its sole
            discretion.Company will notify Customer if there is any issue with
            or rejection of enrollment. <br />
            <br />
            (b) Portal administration. Company will administer the Evidence
            Portal and its benefits through the Company Evidence Portal and
            Customer communications. <br />
            <br />
            (c) Portal changes. Company may change the Evidence Portal or any
            aspect of it at its sole discretion.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 3 - Proprietary Rights
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) Each party retains all right, title and interest in any and all
            of its copyrights, trademarks, domain names, trade secrets, know-how
            and all other of its intellectual property and/or proprietary rights
            ("Intellectual Property Rights"). <br />
            <br />
            (b) For the avoidance of doubt, (i) Securisea owns and retains all
            right, title and interest in and to the Evidence Portal and all of
            its products and Services, including without limitation all
            software, designs and the look and feel of the Portal, and the
            trademarks, service marks, and logos contained therein ("Marks"),
            and all Intellectual Property Rights in all of the foregoing,
            including without limitation all components used to provide the
            Services or created by the Services or by Securisea in the course of
            providing the Services (the "Company IP Rights"); and (ii) Customer
            owns and retains all right, title and interest in and to the of all
            of the Customer data it includes in the Portal (the "Customer
            Data"). <br />
            <br />
            (c) Subject to Customer's compliance with the Terms of Use,
            Securisea hereby grants Customer and its designated authorized
            employees ("Authorized Users") a non-exclusive, non-transferable,
            non-sublicensable right and license to access and use the Portal, in
            each case solely for Customer's internal business purposes (the
            "Authorized Purpose"). <br />
            <br />
            (d) Customer hereby grants Securisea a non-exclusive,
            non-transferable, non-sublicensable right and license to use the
            Customer Data to provide Services to Customer under the Portal and
            for use in connection with any other product or service Securisea
            provides to Customer. <br />
            <br />
            (e) Each party retain all rights, title and interest in all of its
            copyrights, trademarks, domain names, trade secrets, patents,
            know-how and all other of its intellectual property and/or
            proprietary rights ("Intellectual Property Rights").
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 4 - Prohibited Uses
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) You agree that in compliance with these Terms of Use you will
            not nor will you allow any third party to (i) access, copy, modify,
            adapt, translate or otherwise create derivative works of the
            Services; (ii) reverse engineer, de-compile, disassemble or
            otherwise attempt to discover the source code powering the Service
            (the "Software"); (iii) rent, lease, sell, assign or otherwise
            transfer rights in or to the Service; (iv) remove any proprietary
            notices or labels on the Portal; or (v) use, post, transmit or
            introduce any device, software or routine which interferes or
            attempts to interfere with the operation of the Service or the
            Software. The license rights granted will terminate immediately if
            you fail to comply with the terms of this Agreement. Customer
            further agrees not use the Service or the Portal: 1. In any way that
            violates any applicable federal, state, local, or international law
            or regulation (including, without limitation, any laws regarding the
            export of data or software to and from the US or other countries).
            2. For the purpose of exploiting, harming, or attempting to exploit
            or harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information or otherwise. 3. To
            impersonate or attempt to personate Securisea, a Securisea employee,
            another user, or any other person or entity (including, without
            limitation, by using email addresses associated with any of the
            foregoing). 4. To transmit, or procure the sending of, any
            advertising or promotional material including any junk mail, chain
            letter, or spam or any other similar solicitation. 5. To engage in
            any other conduct that restricts or inhibits anyone's use of the
            Service or Evidence Portal, or which may harm Securisea or any of
            its users. <br />
            <br />
            (b) Additionally, you agree not to: 1. Use any robot, spider, or
            other automatic device, process, or means to access this Portal for
            any purpose, including monitoring or copying any of the material on
            the Portal. 2. Introduce any viruses, Trojan horses, worms, logic
            bombs or other material which is malicious or technologically
            harmful. 3. Attack the Portal via a denial-of-service attack or a
            distributed denial-of-service attack. 4. Attempt to gain
            unauthorized access to, interfere with, damage or disrupt any parts
            of the Portal or any user's use of the Portal. 5. Otherwise attempt
            to interfere with the proper working of the Portal.
          </Typography>

          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 5 - Representations and Warranties
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) By using the Portal, you represent and warrant that: (i) all
            registration information and Customer Information you submit will be
            true, accurate, current, and complete; (ii) you will maintain the
            accuracy of such information and promptly update such registration
            information as necessary; and (iii) you have the legal capacity to
            comply with these Terms of Use. <br />
            <br />
            (b) You agree that if you provide any information that is untrue,
            inaccurate, not current, or incomplete, we have the right to suspend
            or terminate your account and to refuse any and all current or
            future Services or use of the Portal. <br />
            <br />
            (c) Securisea represents and warrants to it owns the Portal, has the
            right to enter this Agreement, has the right to perform all Services
            in connection therewith and will perform the Service in a good,
            diligent and workmanlike manner.
          </Typography>

          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 6 - Confidentiality and Personal Data
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) Definition of Confidential Information. Each party who receives
            information hereunder ("Recipient") understands that the other party
            ("Discloser") has disclosed or may disclose the information set
            forth below, which has commercial value in Discloser's business and
            is confidential or proprietary in nature, and which to the extent
            previously, presently or subsequently disclosed to Recipient is
            hereinafter referred to as "Confidential Information" of Discloser.
            Confidential Information includes any information that, given the
            circumstances of disclosure, and/or the nature of the information,
            that Recipient knew or should have known was Confidential
            Information, and includes, without limitation, Discloser's software,
            algorithms, source code, and technology. <br />
            <br />
            (b) Non-Disclosure and Non-Use Obligations. Each party agrees: (i)
            to hold Discloser's Confidential Information in strict confidence,
            and to take precautions to protect such Confidential Information
            from unauthorized disclosure and to use precautions at least as
            strict as Recipient employs with respect to its most confidential
            materials, but in no case less than reasonable precautions; (ii) not
            to disclose any such Confidential Information or any information
            derived therefrom to any third party; (iii) not to make any use
            whatsoever at any time of such Confidential Information except for
            the sole limited business purposes of providing the Service (in the
            case of Securisea) and either in support of a paid Service agreement
            or to evaluate whether to enter into such an agreement (in the case
            of Customer). <br />
            <br />
            (c) The foregoing obligations shall not apply to information that
            Recipient can document: (1) becomes generally available to the
            public through no improper action or inaction by Recipient; (2) was
            in its possession or known by it prior to receipt from Discloser;
            (3) was rightfully disclosed to it by a third party without
            restriction; or (4) was independently developed by Recipient without
            use of or reference to Confidential Information. <br />
            <br />
            (d) Ownership; Return of Confidential Information. All Confidential
            Information, and any derivative works thereof, remains the property
            of Discloser and no license or other rights to Confidential
            Information is granted to the Recipient hereunder. Upon the
            expiration of the Trial Term or upon the request of Discloser at any
            time, (i) Recipient will return to Discloser all Confidential
            Information of Discloser, and any copies or extracts thereof, and
            (ii) Customer shall remove and return or destroy any elements of the
            Software that may have been installed on its computer systems in
            connection with the Service and to certify as to the return or
            destruction of same upon Securisea's request; provided, that, in the
            event that Customer and Securisea enter into a paid Service
            agreement prior to expiration of the Term hereof, the
            confidentiality obligations in such paid Service agreement shall
            supersede the terms of in this Section 6 and shall thereafter apply
            to any Confidential Information hereunder. <br />
            <br />
            (e) Duration of Obligations. The nondisclosure and nonuse
            obligations of each Recipient hereunder shall survive for five (5)
            years from the date of this Agreement; provided, that, such
            obligations shall survive (i) thereafter with respect to a
            Discloser's software and technology-based trade secrets so long as
            they remain eligible for trade secret protection under prevailing
            law (without regard to any breach of Recipient), and (ii) for the
            period prescribed by law with respect to Personal Data. <br />
            <br />
            (f) Data Protection Legislation. The parties will comply with
            prevailing law as it pertains to Personal Data (as defined in the
            CCPA) and Personal Information (as defined in the GDPR), including,
            without limitation and as may be applicable, the California Consumer
            Privacy Act of 2018 (Cal. Civ. Code § 1798.100 et seq.) (the "CCPA")
            and the General Data Protection Regulation (EU) 2016/679 ("GDPR"),
            each as amended (together referred to as "Personal Data"). However,
            the parties acknowledge and agree that except for the names and
            contact information of employees of each party as needed to conduct
            the Services and business relationship ("Incidental Data"), no
            Personal Data will be exchanged. Further, Securisea does not require
            any Personal Data to perform the Services and Customer agrees for
            the benefit of both parties that it will limit its disclosure to
            Securisea hereunder to Incidental Data. <br />
            <br />
            (g) Most commonly, we will use your Personal Data: • to perform the
            Services, to maintain internal records for administration purposes
            related to such contracts, and for the purposes contemplated in any
            paid service agreement for other services that you have entered with
            us. • Where it is necessary for our business purposes. • Where we
            need to comply with a legal or regulatory obligation. <br />
            <br />
            (h) We will retain Personal Data throughout the duration we are
            providing services, or if longer, for a period of three (3) years,
            or such longer time period as is required or permitted by the
            applicable standard(s) or law(s). We reserve the right to retain
            metadata and file names in perpetuity. We will also retain Personal
            Data that is embedded in audit evidence with no time limit that (i)
            allows Securisea to establish that it performed the Services
            properly, and (ii) allows us to confirm the same for your benefit as
            a Customer. <br />
            <br />
            (i) In addition to the above, and notwithstanding the foregoing, we
            may collect and use data and information relating to Customer's use
            and performance of the Services and related systems and
            technologies, and will be free during and after the term hereof to
            (i) use such information and data to improve and enhance the
            Services and for other development, diagnostic and corrective
            purposes in connection with the Services and other Company
            offerings, and (ii) disclose such data solely in anonymized,
            aggregate or other de-identified form in connection with its
            business. <br />
            <br />
            (j) Securisea has implemented security measures designed to secure
            your information from accidental loss and from unauthorized access,
            use, alteration, and disclosure. The safety and security of your
            information also depends on you. Where we have given you (or where
            you have chosen) a password and username for access to the Portal,
            you are responsible for keeping this password and login credentials
            confidential, and you agree not to share the same with any other
            party. Customer also agrees to implement appropriate security
            measures designed to secure your information, your Portal
            credentials, and any Confidential Information received from
            Securisea.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 7 - Disclaimers, Limitation of Liability and Indemnity.
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) Disclaimers. except for the express warranties set forth in
            section 5 (representations and warranties), securisea provides its
            services on an "as is" basis and without any warranties whatsoever,
            express or implied, including without limitation the implied
            warranties of merchantability, non-infringement, and fitness for a
            particular purpose, with respect to its services and the portal
            securisea does not warrant that the services and portal will be
            error-free or complete or that it will meet customer's needs or
            solve its problems. <br />
            <br />
            (b) Limitation of Liability in no event shall securisea or its
            licensors or agents be liable for any direct, indirect, special,
            incidental, consequential or punitive damages, including but not
            limited to loss of revenue, loss of profits, loss of business or
            anticipated savings, loss of use, loss of goodwill or loss of data,
            whether caused by tort (including negligence), breach of contract or
            otherwise, even if advised of the possibility thereof. additionally,
            in no event shall the company's aggregate liability arising out of
            this agreement exceed five hundred dollars (U.S.D. $500). <br />
            <br />
            (c) the limitations in this section above shall apply even if the
            remedies herein shall fail of their essential purpose but do not
            apply to the extent either arising from intentional acts or
            prohibited by applicable law. <br />
            <br />
            (d) Indemnification You agree to defend, indemnify and hold harmless
            Securisea, its affiliates, licensors and service providers, and its
            and their respective officers, directors, employees, contractors,
            agents, licensors, suppliers, successors and assigns from and
            against any claims, liabilities, damages, judgments, awards, losses,
            costs, expenses or fees (including reasonable attorneys' fees)
            arising out of or relating to your violation of these Terms of Use
            or your use of the Portal, including, but not limited to any use of
            the Portal's content, services and products other than as expressly
            authorized in these Terms of Use or your use of any information
            obtained from the Portal.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            SECTION 8 - General Provisions
          </Typography>
          <Typography sx={{ pb: 2 }}>
            (a) Changes to the Terms of Use. We may revise and update these
            Terms of Use from time to time in our sole discretion. All changes
            are effective immediately when we post them and apply to all access
            to and use of the Portal thereafter. Your continued use of the
            Portal following the posting of revised Terms of Use means that you
            accept and agree to the changes. You are expected to check this page
            frequently if you continue to access the Portal, so you are aware of
            any changes, as they are binding on you. <br />
            <br />
            (b) Law and Venue. These Terms of Use will be construed in
            accordance with and governed by the laws of the State of California
            and applicable U.S. federal laws, without regard to conflict of laws
            provisions. Jurisdiction and venue for any actions arising under or
            relating in any way to these Terms of Use or the Services will vest
            exclusively in the federal and state courts in the State of
            California. <br />
            <br />
            (c) Copyright Claims. If you believe that any material residing on
            or linked to from the Portal infringes your copyright, please send a
            notice of claimed infringement to the Company at by e-mail to
            [Legal@securisea.com]. Please include the following information with
            such notice: (i) identification of the copyrighted work claimed to
            have been infringed, or, if multiple copyrighted works are covered
            by a single notification, a representative list of such works; (ii)
            identification of the claimed infringing material and information
            reasonably sufficient to permit the Company to locate the material
            on the Website (such as the URL(s) of the claimed infringing
            material); and (iii) information reasonably sufficient to permit the
            Company to contact you, including an address, telephone number and,
            if available, an email address. <br />
            <br />
            (d) Severability; Waiver; Headings. If any provision of this
            Agreement may prove to be null and void or otherwise unenforceable,
            the parties shall negotiate to replace that provision with
            arrangements with a similar legal and commercial effect as much as
            such shall be possible under the applicable law. The waiver of any
            breach or default of this Agreement will not constitute a waiver of
            any subsequent breach or default and will not act to amend or negate
            the rights of the waiving Party. Clause headings are inserted for
            convenience of reference only and shall not affect the
            interpretation of this Agreement. <br />
            <br />
            (e) No Third-Party Beneficiaries. No person or entity, whether or
            not mentioned or referred to in these Terms of Use, other than
            Customer and Securisea and their permitted successors and assigns,
            will be considered to be a third-party beneficiary of or entitled to
            assert any rights under these Terms of Use. <br />
            <br />
            (f) Notices. Notices may be provided either by electronic mail. The
            contact person(s) identified during enrollment in the Evidence
            Portal will receive notices at the email addresses provided by
            Customer, and provided separately or posted by Securisea. Each party
            may change the persons to whom notices will be sent by giving notice
            to the other. Notices are considered delivered on the date shown on
            the confirmation of delivery. Customer will give Company prompt
            notice if Customer becomes insolvent or enters insolvency,
            bankruptcy or other similar proceedings under applicable laws.{" "}
            <br />
            <br />
            (g) No Assignment. Customer may not assign any of its rights or
            obligations hereunder, whether by operation of law or otherwise,
            without the prior written consent of Company. Subject to the
            foregoing, the terms of this Agreement shall bind and inure to the
            benefit of the Parties and their respective successors and permitted
            assigns. <br />
            <br />
            (h) Entire Agreement. This Agreement together with any separate
            Service agreement or order for Services accepted by both parties in
            writing is the entire agreement between the parties and supersedes
            any arrangements, understandings, promises, or agreements regarding
            the same subject matter, whether written or oral, made or existing
            between the parties prior to or simultaneously with this Agreement,
            and constitutes the entire understanding between the parties. No
            addition, amendment to or modification of this Agreement shall be
            effective unless it is in writing and signed by and on behalf of
            both parties. In the event of any conflict or inconsistency between
            the provisions of this Agreement and any Order, the same shall be
            resolved by giving precedence to this Agreement
          </Typography>
          <Typography sx={{ pb: 2 }}>
            These Terms of Use were last updated on September 10, 2024.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} variant="contained" color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsPopup;
