import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  useEvidenceRequestChat,
  useToggleMessageHidden,
  useGetChatUsers,
} from "../../hooks/evidencerequests";
import { getUserInfo } from "../../helpers/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User } from "../../types/users";
import { styled } from "@mui/system";

const MentionTag = styled("span")({
  fontWeight: "bold",
  color: "#2196f3",
  borderRadius: "4px",
});

const TAG_START = "<user-start>"; // Start of Heading
const TAG_END = "<user-end>"; // Start of Text

const parseMessageContent = (content: string, users: User[]) => {
  // Define start and end tags for mentions
  const parts = content.split(
    new RegExp(`(${TAG_START}.*?${TAG_END}|\n)`, "g")
  );

  return parts.map((part, index) => {
    if (part.startsWith(TAG_START) && part.endsWith(TAG_END)) {
      // Extract user details from the tag
      const tagContent = part.slice(TAG_START.length, -TAG_END.length);
      const [userId, ...rest] = tagContent.split(":");
      const user = users.find((u) => u.id === userId);
      const displayText = rest.join(":") || (user ? user.name : userId);

      // Render mention tag
      return (
        <MentionTag key={index} title={user ? user.email : ""}>
          @{displayText}
        </MentionTag>
      );
    } else if (part === "\n") {
      // Render line breaks as <br />
      return <br key={index} />;
    } else {
      // Render regular text
      return part;
    }
  });
};

interface ChatMessageProps {
  message: {
    id: string;
    content: string;
    sender: {
      id: string;
      name: string;
      email: string;
      organization: string;
    };
    hidden: boolean;
    users_mentioned: User[];
  };
  userInfo: {
    id: string;
    role: string;
  };
  formattedDate: string;
  toggleHidden: (id: string) => void;
  users: User[] | undefined | [];
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  userInfo,
  formattedDate,
  toggleHidden,
  users,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "80%",
        padding: "8px 12px",
        backgroundColor:
          message.sender.id === userInfo.id ? "#e0f7fa" : "#f1f8e9",
        borderRadius: "10px",
      }}
    >
      <ListItemText
        title={message.sender.email}
        primary={
          <>
            {message.sender.id === userInfo.id
              ? "You"
              : `${message.sender.name} (${message.sender.organization})`}
            : {parseMessageContent(message.content, users || [])}
          </>
        }
        primaryTypographyProps={{
          style: { wordWrap: "break-word" },
          component: "div",
        }}
        secondary={`Sent at ${formattedDate}`}
      />
      {message.sender.organization === "Securisea" &&
        userInfo.role === "admin" && (
          <>
            {message.hidden ? (
              <VisibilityOff
                color="action"
                onClick={() => toggleHidden(message.id)}
              />
            ) : (
              <Visibility
                color="action"
                onClick={() => toggleHidden(message.id)}
              />
            )}
          </>
        )}
    </Box>
  );
};

const EvidenceRequestChatInterface: React.FC<{
  conversationId: string;
  refetch: CallableFunction;
}> = ({ conversationId, refetch }) => {
  const { conversation, isLoading, sendMessage } =
    useEvidenceRequestChat(conversationId);
  const [currentDisplayMessage, setCurrentDisplayMessage] = useState("");
  const [currentPayloadMessage, setCurrentPayloadMessage] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [cursorPosition, setCursorPosition] = useState(0);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { users, isLoading: isLoadingUsers } = useGetChatUsers(conversationId);
  const userInfo = getUserInfo();
  const { toggleHidden } = useToggleMessageHidden(conversationId);

  useEffect(() => {
    const lastAtIndex = currentDisplayMessage.lastIndexOf("@");
    if (lastAtIndex !== -1 && lastAtIndex < cursorPosition) {
      const textAfterAt = currentDisplayMessage
        .slice(lastAtIndex + 1, cursorPosition)
        .toLowerCase();
      if (users && Array.isArray(users)) {
        const filtered = users.filter((user: User) =>
          user.name.toLowerCase().startsWith(textAfterAt)
        );
        setFilteredUsers(filtered);
        setShowUserList(filtered.length > 0);
      }
    } else {
      setShowUserList(false);
    }
  }, [currentDisplayMessage, cursorPosition, users]);

  const handleSendMessage = (event: React.FormEvent) => {
    event.preventDefault();

    const usersMentioned: string[] = [];

    const processedMessage = currentPayloadMessage.replace(
      new RegExp(`${TAG_START}(.*?)${TAG_END}`, "g"),
      (match, content) => {
        const [userId, ...rest] = content.split(":");
        usersMentioned.push(userId);
        return `@${rest.join(":")}`;
      }
    );

    sendMessage({
      content: currentPayloadMessage, // Send the payload message with tags
      usersMentioned: usersMentioned,
    });
    setCurrentDisplayMessage("");
    setCurrentPayloadMessage("");
  };

  useEffect(() => {
    refetch();
  }, [currentDisplayMessage]);

  const scrollToBottom = () => {
    const scrollHeight = messagesContainerRef.current?.scrollHeight;
    const height = messagesContainerRef.current?.clientHeight;
    const maxScrollTop = scrollHeight! - height!;
    messagesContainerRef.current?.scrollTo({
      top: maxScrollTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.messages]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCurrentDisplayMessage(newValue);

    // Update payload message by replacing @ mentions with tags
    let updatedPayload = newValue;
    if (users && Array.isArray(users)) {
      users.forEach((user) => {
        const regex = new RegExp(`@${user.name}\\b`, "g");
        updatedPayload = updatedPayload.replace(
          regex,
          `${TAG_START}${user.id}:${user.name}${TAG_END}`
        );
      });
    }
    setCurrentPayloadMessage(updatedPayload);

    setCursorPosition(event.target.selectionStart || 0);
  };

  const handleUserSelect = (user: User) => {
    if (inputRef.current) {
      setShowUserList(false);
      const lastAtIndex = currentDisplayMessage.lastIndexOf("@");
      const beforeAt = currentDisplayMessage.slice(0, lastAtIndex);
      const afterCursor = currentDisplayMessage.slice(cursorPosition);

      const newDisplayMessage = `${beforeAt}@${user.name} ${afterCursor}`;
      const newPayloadMessage = `${beforeAt}${TAG_START}${user.id}:${user.name}${TAG_END} ${afterCursor}`;

      setCurrentDisplayMessage(newDisplayMessage);
      setCurrentPayloadMessage(newPayloadMessage);
      inputRef.current.focus();

      const newCursorPosition = lastAtIndex + user.name.length + 1;
      setTimeout(() => {
        inputRef.current?.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }, 0);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && (event.shiftKey || event.ctrlKey)) {
      const c = confirm("Are you sure you want to send the message?");
      if (c) {
        handleSendMessage(event);
      }
    }
  };

  if (isLoading) return <div>Loading conversation...</div>;

  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto" }}>
      <Box
        ref={messagesContainerRef}
        sx={{ maxHeight: "45vh", overflow: "auto", marginBottom: "1rem" }}
      >
        <List>
          {conversation?.messages?.map((message) => {
            const formattedDate = new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(new Date(message.timestamp));
            return (
              <ListItem
                key={message.id}
                sx={{
                  display: "flex",
                  justifyContent:
                    message.sender.id === userInfo.id
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <ChatMessage
                  key={message.id}
                  message={message}
                  userInfo={userInfo}
                  formattedDate={formattedDate}
                  toggleHidden={toggleHidden}
                  users={users}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          "& > :not(style)": { m: 1 },
          position: "relative",
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSendMessage}
      >
        {showUserList && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              bottom: "100%",
              left: 0,
              right: 0,
              maxHeight: 200,
              overflow: "auto",
            }}
          >
            <List>
              {filteredUsers.map((user) => (
                <ListItem
                  key={user.id}
                  onClick={() => handleUserSelect(user)}
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemText
                    primary={user.name + " - " + user.organization}
                    secondary={user.email}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        <TextField
          multiline
          rows={3}
          fullWidth
          inputRef={inputRef}
          label="Type a message..."
          variant="outlined"
          value={currentDisplayMessage}
          onChange={handleMessageChange}
          onKeyDown={handleKeyPress}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default EvidenceRequestChatInterface;
