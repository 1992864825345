import React from "react";
import Layout from "../../layout";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { getUserInfo } from "../../helpers/user";
import UserEvidenceRequestPage from "../evidencerequests/user";

const Home = () => {
  const user = getUserInfo();
  const hometitle = `Welcome, ${user.first_name}`;

  return (
    <Layout type="home" pageTitle={hometitle} parentUrl="" parentPageName="">
      <Box sx={{ flexGrow: 1, padding: 2, pt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderColor: "transparent" }}>
              <CardContent sx={{ pt: 0 }}>
                <Box
                  sx={{
                    textAlign: "end",
                    backgroundColor: "grey.100",
                    p: 0,
                  }}
                >
                  <UserEvidenceRequestPage cardMode={true} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Home;
